import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { KeystoneRoute } from '../models/keystone-route';
import { QueryParams, UrlSegment } from '../models/keystone-route-url';

interface HistoryRoute {
  url: string;
  path: string;
}

@Injectable({
  providedIn: 'root'
})
export class KeystoneRouterService implements OnDestroy {
  private static queryParamSplitterRegex = /;|\?/;

  private navigationEnd$$: Subscription;
  private navigationStart$$: Subscription;

  private pHistory: HistoryRoute[] = [];
  private pCurrentRoute: KeystoneRoute;
  private pQueryParamsStore: { [key: string]: QueryParams };
  private pRouteChangedEvent: BehaviorSubject<KeystoneRoute>;

  get currentRoute(): KeystoneRoute {
    return this.pCurrentRoute;
  }

  get routeChanged(): Observable<KeystoneRoute> {
    return this.pRouteChangedEvent.asObservable();
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.pCurrentRoute = new KeystoneRoute(this.activatedRoute.snapshot);
    this.pRouteChangedEvent = new BehaviorSubject<KeystoneRoute>(this.currentRoute);
    this.pQueryParamsStore = {};

    this.navigationEnd$$ = this.startNavigationEndListener();
    this.navigationStart$$ = this.startNavigationStartListener();
  }

  navigate(urlSegments: UrlSegment[], queryParams: QueryParams = null) {
    let urlCommands = urlSegments;

    if (queryParams != null) {
      urlCommands = urlCommands.concat([queryParams]);
    }

    this.router.navigate(urlCommands);
  }

  navigateBack(): boolean {
    if (this.pHistory.length >= 2) {
      this.pHistory.pop();
      this.router.navigateByUrl(this.pHistory.pop().url);
      return true;
    }

    this.pHistory.length = 0;
    return false;
  }

  ngOnDestroy(): void {
    this.navigationEnd$$.unsubscribe();
    this.navigationEnd$$ = null;

    this.navigationStart$$.unsubscribe();
    this.navigationStart$$ = null;
  }

  private startNavigationEndListener(): Subscription {
    return this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((navigationEnd: NavigationEnd) => {
      this.pCurrentRoute = new KeystoneRoute(this.activatedRoute.snapshot);

      if (this.pHistory.length > 0 && this.pHistory[this.pHistory.length - 1].path === this.pCurrentRoute.url.path) {
        this.pHistory[this.pHistory.length - 1].url = navigationEnd.urlAfterRedirects;
      } else {
        this.pHistory.push({ url: navigationEnd.urlAfterRedirects, path: this.pCurrentRoute.url.path});
      }

      const hasQueryParams = this.pCurrentRoute.url.queryParams != null && Object.keys(this.pCurrentRoute.url.queryParams).length > 0;

      if (this.pCurrentRoute.keepQueryParams) {
        const paramsToStore = {};
        for (const [key, value] of Object.entries(this.pCurrentRoute.url.queryParams)) {
          if (!this.pCurrentRoute.ignoredQueryParams.includes(key)) {
            paramsToStore[key] = value;
          }
        }

        const hasParamsToStore = Object.keys(paramsToStore).length > 0;

        this.pQueryParamsStore[this.pCurrentRoute.url.path] = (hasQueryParams && hasParamsToStore) ? paramsToStore : null;
      }

      this.pRouteChangedEvent.next(this.pCurrentRoute);
    });
  }

  private startNavigationStartListener(): Subscription {
    return this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((navigationStart: NavigationStart) => {
      if (navigationStart.url != null) {
        const urlAndQueryParams = navigationStart.url.split(KeystoneRouterService.queryParamSplitterRegex);
        const urlWithoutQueryParams = urlAndQueryParams[0];

        if (urlAndQueryParams.length === 1 && this.pCurrentRoute?.url.path !== urlWithoutQueryParams) {
          const expectedQueryParams = this.pQueryParamsStore[urlWithoutQueryParams];

          if (expectedQueryParams != null) {
            this.router.navigate([urlWithoutQueryParams, expectedQueryParams]);
          }
        }
      }
    });
  }
}
