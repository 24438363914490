import { Component } from '@angular/core';
import { KeystoneRouterService } from './framework/navigation/services/keystone-router.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FrontSystems SuperAdmin Portal';

  constructor(keystoneRouterService: KeystoneRouterService) { }
}
