import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServerSettingsManagerService } from '@keystone-angular/core';
import { appInitialization } from './app-initialization.function';
import { InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import {
  MsalBroadcastService, MsalGuard, MsalGuardConfiguration,
  MsalInterceptor, MsalInterceptorConfiguration, MsalRedirectComponent, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2c.clientId,
      authority: `https://${environment.b2c.baseAuthority}/${environment.b2c.tenantId}/${environment.b2c.policies.signIn}`,
      redirectUri: location.origin,
      postLogoutRedirectUri: location.origin + '/logged-out',
      navigateToLoginRequestUrl: false,
      knownAuthorities: [`${environment.b2c.baseAuthority}/${environment.b2c.tenantId}`]
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: environment.production ? LogLevel.Error : LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  for (const { url, scopes } of environment.b2c.protectedResources) {
    protectedResourceMap.set(url, scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const scopes: any = [...new Set(environment.b2c.protectedResources
    .map(resource => resource.scopes)
    .reduce((cur, acc) => acc.concat(cur), [])
  )];

  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes
    },
    loginFailedRoute: ''
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoggedOutComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitialization,
      deps: [ServerSettingsManagerService],
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    MsalBroadcastService,
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
