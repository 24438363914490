export interface QueryParams {
  [param: string]: any;
}

export interface PathParams {
  [param: string]: any;
}

export type UrlSegment = any;

export class KeystoneRouteUrl {
  path: string;
  segments: UrlSegment[];
  pathParams: PathParams;
  queryParams: QueryParams;

  constructor() {
    this.path = '';
    this.segments = [];
    this.pathParams = {};
    this.queryParams = {};
  }
}
