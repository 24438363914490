export const environment = {
  production: false,
  b2c: {
    baseAuthority: 'b2c.frontsystems.com',
    clientId: 'fd07cc60-ee82-461a-97d5-2351d152d822',
    policies: {
      signIn: 'B2C_1A_SIGNINWITHSUPERADMINAAD_EXTERNAL_DEVELOPMENT'
    },
    protectedResources: [
      { url: 'https://fs-admin-api-dev.frontsystems.com', scopes: ['https://b2c.frontsystems.com/dev/superadmin/api/all'] },
      { url: 'https://localhost:44308', scopes: ['https://b2c.frontsystems.com/dev/superadmin/api/all'] }
    ],
    tenantId: '5a9ada72-018a-4c33-a9f6-25cab8d8ff37'
  },
  dinbutikkUrl: 'https://dinbutikkv2-dev.frontsystems.no'
};
