import { ActivatedRouteSnapshot, Params, UrlSegment } from '@angular/router';
import { BreadcrumbItem } from '@keystone-angular/core';
import { KeystoneRouteUrl, PathParams } from './keystone-route-url';

export class KeystoneRoute {
  private pBreadcrumb: BreadcrumbItem[];
  private pUrl: KeystoneRouteUrl;
  private pKeepQueryParams: boolean;
  private pIgnoredQueryParams: string[];

  get breadcrumb(): BreadcrumbItem[] {
    return this.pBreadcrumb;
  }

  get keepQueryParams(): boolean {
    return this.pKeepQueryParams;
  }

  get url(): KeystoneRouteUrl {
    return this.pUrl;
  }

  get ignoredQueryParams(): string[] {
    return this.pIgnoredQueryParams;
  }

  constructor(currentRoute: ActivatedRouteSnapshot) {
    this.pUrl = new KeystoneRouteUrl();
    this.pBreadcrumb = [];

    if (currentRoute && currentRoute.root) {
      this.fillRouteData(currentRoute.root);
    }
  }

  private fillRouteData(route: ActivatedRouteSnapshot): void {
    let pointer = route;
    let lastSegment = null;
    let allParamsBySegment = [];

    do {
      if (pointer.url.length > 0) {
        lastSegment = pointer.url[pointer.url.length - 1];
        allParamsBySegment = allParamsBySegment.concat([pointer.params]);

        this.pUrl.segments = this.pUrl.segments.concat(pointer.url.map(segment => segment.path));

        const breadcrumb = this.getBreadcrumb(pointer);

        if (breadcrumb) {
          this.pBreadcrumb = this.pBreadcrumb.concat([breadcrumb]);
        }
      }

      if (pointer.children.length === 0) {
        this.pKeepQueryParams = pointer.data?.keepQueryParams || false;
        this.pIgnoredQueryParams = pointer.data?.ignoredQueryParams || [];

        this.pUrl.path = `/${this.pUrl.segments.join('/')}`;
        this.pUrl.pathParams = this.getPathParams(allParamsBySegment, lastSegment);
        this.pUrl.queryParams = Object.assign({}, lastSegment?.parameters, route.queryParams);

        pointer = null;
      } else {
        pointer = pointer.children[0];
      }
    } while (pointer != null);
  }

  private getPathParams(allParamsBySegment: Params, lastSegment: UrlSegment): PathParams {
    const pathParams = {};

    allParamsBySegment.forEach(paramsBySegment => {
      const keys = Object.keys(paramsBySegment);

      keys.forEach(param => {
        if (lastSegment.parameters[param] == null) {
          pathParams[param] = paramsBySegment[param];
        }
      });
    });

    return pathParams;
  }

  private getBreadcrumb(route: ActivatedRouteSnapshot): BreadcrumbItem {
    const breadcrumbUrl = `/${route.pathFromRoot.filter(segment => segment.url.length > 0).map(segment => segment.url).join('/')}`;

    const breadcrumbData: { label: string, params: string[] } = route.data.breadcrumb;

    if (breadcrumbData && breadcrumbData.label) {
      const dynamicRouteParameters = [];

      if (breadcrumbData.params) {
        breadcrumbData.params.forEach((currentParameter) => {
          if (currentParameter.startsWith(':') && route.params) {
            const param = Object.entries(route.params).find(([key, _]) =>
              currentParameter.substring(1) === key);

            if (param) {
              dynamicRouteParameters.push(param[1]);
            }
          }
        });
      }

      return {
        label: this.formatLabel(breadcrumbData.label, dynamicRouteParameters),
        url: breadcrumbUrl
      };
    }
  }

  private formatLabel(label: string, parameters: any[]): string {
    return label.replace(/{(\d+)}/g, (match, number) =>
      typeof parameters[number] != null ? parameters[number] : match
    );
  }
}
