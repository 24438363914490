import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { NotificationService } from '@keystone-angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private notificationService: NotificationService, private msalGuard: MsalGuard,
    private msalService: MsalService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    this.msalGuard.canActivate(route, state).subscribe(response => {
      if (response) {
        return of(response);
      }

      this.notificationService.showError('The given account was not found in the Global Database. Contact a developer for further instructions.');
      setTimeout(() => { this.msalService.logout(); }, 5000);
    });

    return of(true);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.msalGuard.canActivateChild(childRoute, state);
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.msalGuard.canLoad();
  }
}
