<ng-container>
  <div class="logout-page">
    <div class="logout-page-left">
      <img class="logout-page-background" src="images/login-background.jpg" alt="Front Systems background" aria-hidden="true">
    </div>
    <div class="logout-page-right">
      <div class="logout-page-right-content">
        <img alt="Front Systems logo" class="logout-page-company-logo" src="/images/front_logo_original_blue.png">
        <div class="logout-page-logout-container">
          <p class="logout-page-logout-message">You have been successfully logged out</p>
          <button class="logout-page-button" (click)="returnToLogin()">Log in</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
