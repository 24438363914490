import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss']
})
export class LoggedOutComponent {

  constructor(private msalService: MsalService) { }

  returnToLogin() {
    this.msalService.loginRedirect();
  }
}
